import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyA849ZPDFSUw2nCaFoXVdbEJHXeY-Ud2tk",
    authDomain: "social-23e2e.firebaseapp.com",
    databaseURL: "https://social-23e2e.firebaseio.com",
    projectId: "social-23e2e",
    storageBucket: "social-23e2e.appspot.com",
    messagingSenderId: "958674967483",
    appId: "1:958674967483:web:3a63a08df98a9a85ea429e"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();


export { auth, db, firebase };