import logo from '../assets/images/logos/sle_logo.png';
import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { NavLink, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getAuth, signOut } from 'firebase/auth';

const loginTimestampKey = 'loginTimestamp';

const setLoginTimestamp = () => {
  const now = new Date().getTime();
  localStorage.setItem(loginTimestampKey, now.toString());
};

const checkSessionValidity = () => {
  const loginTimestamp = localStorage.getItem(loginTimestampKey);
  const now = new Date().getTime();

  if (loginTimestamp && now - parseInt(loginTimestamp, 10) > 3600000) {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Redirect to login or do other clean up
    });
  }
};

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const success = () => {
    toast.success('Login Successful', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
    });
  };

  const failure = () => {
    toast.error('Login Failed', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      theme: 'dark',
    });
  };

  const onLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log('Login success', user);
      success();
      setLoginTimestamp();
      if (user.uid !== 'TvlfV1Jt4nQQTo2cqIIN2nh9LBM2' && user.uid !== 'xqMyp5YoHqZ8ZjPskxt71MgHVMQ2') {
        navigate('/orders');
      } else {
        navigate('/home');
      }
    } catch (error) {
      console.error('Login error:', error.code, error.message);
      failure();
    }
  };

  return (
      <main>
        <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
          <div style={{ maxWidth: '90%', width: '300px' }}>
            <img src={logo} alt="SLE-Login Logo" style={{ width: '100%', marginBottom: '20px' }} />
            <form style={{ boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: '10px' }}>
              <div>
                <input
                    id="email-address"
                    name="email"
                    type="email"
                    required
                    placeholder="Email address"
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ width: '100%', padding: '10px', margin: '10px 0', boxSizing: 'border-box' }}
                />
              </div>
              <div>
                <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ width: '100%', padding: '10px', margin: '10px 0', boxSizing: 'border-box' }}
                />
              </div>
              <div>
                <button
                    type="button"
                    onClick={onLogin}
                    style={{
                      width: '100%',
                      padding: '10px',
                      backgroundColor: '#007bff',
                      color: 'white',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </section>
      </main>
  );
};

export default Login;
