import React, { useEffect, useState,useMemo } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Footer from '../components/Footer';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../styles/globals.css';
import { useHistory } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { NavLink, useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { signOut } from "firebase/auth";
import { ToWords } from 'to-words';
import MyInnerRenderer from "./myInnerRenderer.jsx";
import { MenuItem, Select } from '@mui/material';
import DisplayHTML from './DisplayHTML';

export default function Orders() {

  const loginTimestampKey = 'loginTimestamp';
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeTab, setActiveTab] = useState('Default');
  const [selectedValue, setSelectedValue] = useState('');
  const [rawData, setRawData] = useState({});
  const [groupByPO, setGroupByPO] = useState({});
  const [groupByComponents, setGroupByComponents] = useState({});
  const [currentUserUID, setCurrentUserUID] = useState(null);
  function formatDate(dateStr) {
    const [day, month, year] = dateStr.split('/');
    return `${day}/${month}/${year}`; // Converts DD/MM/YYYY to YYYY-MM-DD
  }
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 80,
      cellStyle: {fontSize: '8px'}
    };
  }, []);
  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 300,
      cellRendererParams: {
        innerRenderer: MyInnerRenderer,
      },
    };
  }, []);
  function dateComparator(date1, date2) {
    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split('/');
      return new Date(year, month - 1, day);
    };

    const dateObj1 = parseDate(date1);
    const dateObj2 = parseDate(date2);

    console.log(`Comparing ${dateObj1} to ${dateObj2}`);

    return dateObj1 - dateObj2;
  }

  console.log("DEFAULT BOOLEAN:",(currentUserUID === "TvlfV1Jt4nQQTo2cqIIN2nh9LBM2" || currentUserUID === "xqMyp5YoHqZ8ZjPskxt71MgHVMQ2"));
  const columnDefs = [
    { headerName: 'PO Ref', field: 'poRef', width: 120,sortingOrder: ['asc', 'desc'] ,suppressMovable:true  },
    { headerName: 'Item', field: 'item', width: 130,sortingOrder: ['asc', 'desc'] ,suppressMovable:true },
    { headerName: 'Drawing', field: 'drawing', width: 130 ,sortingOrder: ['asc', 'desc'],suppressMovable:true},
    { headerName: 'Item Rev', field: 'itemRev', width: 40 ,sortingOrder: ['asc', 'desc'],suppressMovable:true},
    { headerName: 'Ordered', field: 'qtyOrdered', type: 'number', width: 80,sortingOrder: ['asc', 'desc'] ,suppressMovable:true},
    { headerName: 'Due', field: 'qtyDue', type: 'number', width: 80,sortingOrder: ['asc', 'desc'],suppressMovable:true },
    { headerName: 'Due Date', field: 'dueDate', width: 120 , sort: 'asc',comparator: dateComparator, sortable:true,suppressMovable:true,sortingOrder: ['asc', 'desc'] },
    { headerName: 'Promised Date', field: 'promisedDate', width: 140 , sort: 'asc', comparator: dateComparator,sortable:true,suppressMovable:true,sortingOrder: ['asc', 'desc'] },
    { headerName: 'Material Supplier', field: 'materialSupplier', width: 160 ,sortingOrder: ['asc', 'desc'],suppressMovable:true},
    { headerName: 'Material Available', field: 'materialAvailable', width: 120,sortingOrder: ['asc', 'desc'],suppressMovable:true },
    { headerName: 'Notes', field: 'notes', minwidth: 200 , maxwidth: 400 ,sortingOrder: ['asc', 'desc'], autoHeight:true,suppressMovable:true},
    {
      headerName: 'Unit Price',
      field: 'unitPrice',
      type: 'number',
      width: 130,suppressMovable:true,
      hide: !(currentUserUID === "TvlfV1Jt4nQQTo2cqIIN2nh9LBM2" || currentUserUID === "xqMyp5YoHqZ8ZjPskxt71MgHVMQ2"),
      sortingOrder: ['asc', 'desc'],valueParser: "Number(newValue)",
    },
    {
      headerName: 'ORDER WORTH',
      field: 'orderWorth',
      type: 'number',
      width: 130,suppressMovable:true,
      aggFunc: params => {
        let total = 0;
        params.values.forEach(value => total += value);
        return total;
      },
      hide: !(currentUserUID === "TvlfV1Jt4nQQTo2cqIIN2nh9LBM2" || currentUserUID === "xqMyp5YoHqZ8ZjPskxt71MgHVMQ2"),
      sortingOrder: ['asc', 'desc'],valueParser: "Number(newValue)",
    }
  ];

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setCurrentUserUID(user.uid); // Store the user's UID
        // other existing code
      } else {
        navigate('/');
      }
    });

    // other existing code

    return () => unsubscribe();
  }, []);


  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (!user) {
        // User not logged in, redirect to login page
        navigate('/');
      } else {
        // User is logged in, now check if session is valid
        checkSessionValidity();
      }
    });
    console.log("FETCGING DATA");
    fetch('https://awesome.slemachinetech.com/api/extract-table')
      .then(response => response.json())
      .then(fetchedData => {
        setData(fetchedData);
        console.log(fetchedData);

        setLoading(false);
        processGroupByPO(fetchedData);
        processGroupByComponents(fetchedData);
        processRawData(fetchedData);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
    return () => unsubscribe();
  }, []);

  const processRawData = (data) => {
    const indexedData = {};
    data.forEach((item, index) => {
      indexedData[index] = item;  // Index each item by its position in the array
    });
    setRawData(indexedData);  // Assuming setData is your state updater for the full dataset
  };

  const processGroupByPO = (data) => {
    const groupedData = {};
    console.log("Grouped by PO Calling");
    data.forEach(item => {
      console.log("Grouped by PO:",item);
      const poRefKey = item['PO Ref'].split(" ")[0];
      console.log("Grouped by poRefKey:",poRefKey);
      if (!groupedData[poRefKey]) {
        groupedData[poRefKey] = [];
      }
      groupedData[poRefKey].push(item);
      console.log("Grouped by PO:",item);
    });
    console.log("Grouped by PO:", groupedData);
    setGroupByPO(groupedData);
  };

  const processGroupByComponents = (data) => {
    const groupedData = {};
    data.forEach(item => {
      const componentKey = item['Drawing'];
      if (!groupedData[componentKey]) {
        groupedData[componentKey] = [];
      }
      groupedData[componentKey].push(item);
    });
    console.log("Grouped by Components:", groupedData);
    setGroupByComponents(groupedData);
  };

  const checkSessionValidity = () => {
    const loginTimestamp = localStorage.getItem(loginTimestampKey);
    const now = new Date().getTime();

    if (loginTimestamp && (now - parseInt(loginTimestamp, 10) > 3600000)) { // 3600000 ms = 1 hour
      const auth = getAuth();
      signOut(auth).then(() => {
        navigate('/'); // Redirect user to login page after sign out
      });
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setSelectedValue('');
  };


  const renderGroupedTables = (groupedData, groupName) => {
    if (Object.keys(groupedData).length === 0) {
      return <p>No data available for {groupName}.</p>;
    }

    return Object.entries(groupedData).map(([key, items], index) => {
      console.log("Rendering group:", key, "with items:", items);
      let totalOrderWorth = 0;

      const rows = items.map((item, idx) => {
        try {
          const unitPrice = parseFloat(item['Unit Price'].replace(/,/g, ''));
          const qtyDue = parseFloat(item['Qty Due']);
          const orderWorth = unitPrice * qtyDue;
          totalOrderWorth += orderWorth;

          return {
            id: idx,
            poRef: item['PO Ref'],
            item: item['Item'],
            drawing: item['Drawing'],
            itemRev: item['Item Rev'],
            unitPrice: unitPrice,
            orderWorth: orderWorth,
            qtyOrdered: item['Qty Ordered'],
            qtyDue: item['Qty Due'],
            dueDate: item['Due DatePromised Date'].substring(0, 10),
            promisedDate: item['Due DatePromised Date'].substring(10),
            materialSupplier: item['Material Supplier'],
            materialAvailable: item['Material Available'],
            notes: item['Notes'],
          };
        } catch (error) {
          console.error("Error processing item:", item, error);
          return {}; // Return an empty object or handle as needed
        }
      });

      const toWords = new ToWords();
      const words = toWords.convert(totalOrderWorth, { currency: true });
      const maxHeight = rows.length * 95;
      return (
          <Box key={index} sx={{ height: 'auto', width: '100%' }}>
            <h3>{`${groupName}: ${key}`}</h3>
            {(currentUserUID === "TvlfV1Jt4nQQTo2cqIIN2nh9LBM2" || currentUserUID === "xqMyp5YoHqZ8ZjPskxt71MgHVMQ2") &&<h5>{`Total Order Worth: ₹${totalOrderWorth.toFixed(2)} - ${words}`}</h5>}
            <div className="ag-theme-alpine" style={{ width: '100%', maxHeight: `${maxHeight}px`, overflowY: 'auto'  }}>
              <AgGridReact
                  rowData={rows}
                  columnDefs={columnDefs}
                  defaultColDef={{ sortable: true }}
                  domLayout='autoHeight'
                  pagination={false}
                  checkboxSelection
              />
            </div>
          </Box>
      );
    });
  };
  const handleDropdownSelect = (value) => {
    setSelectedValue(value);
  };



  const filteredData = activeTab === 'PO' ? groupByPO[selectedValue] || [] : activeTab === 'Default' ?Object.values(rawData):groupByComponents[selectedValue] || [];
  console.log("filteredData:", filteredData);
  console.log("Selected Value:", selectedValue);

const renderTable = () => {
  if (filteredData.length === 0) {
    return <p>No data available for the selected option.</p>;
  }
  console.log(filteredData[0]);
  let totalOrderWorth = 0;
  const rows = filteredData.map((item, index) => ({
    id: index,
    poRef: item['PO Ref'],
    item: item['Item'],
    drawing: item['Drawing'],
    itemRev: item['Item Rev'],
    // buyer: item['Buyer'],
    unitPrice: item['Unit Price'].replace(/,/g, ''),
    orderWorth: parseFloat(item['Unit Price'].replace(/,/g, '')) * parseFloat(item['Qty Due']), // Ensure proper parsing and calculation
    qtyOrdered: item['Qty Ordered'],
    qtyDue: item['Qty Due'],
    dueDate: item['Due DatePromised Date'].substring(0,10),
    promisedDate: item['Due DatePromised Date'].substring(10),
    materialSupplier: item['Material Supplier'],
    materialAvailable: item['Material Available'],
    notes: item['Notes'],
  }));

  const worthColumnDefs = [
    { headerName: 'Overall Outstanding Worth', field: 'overallOutstandingWorth',suppressMovable:true,
      hide: !(currentUserUID === "TvlfV1Jt4nQQTo2cqIIN2nh9LBM2" || currentUserUID === "xqMyp5YoHqZ8ZjPskxt71MgHVMQ2")},
  ];
  const worthRowData = [
    {
      overallOutstandingWorth: formatInIndianRupees(overallOutstandingWorth),
    },
  ];
  return (
      <div>
        <Box sx={{height: 'auto', width: '100%'}}>
          <div className="ag-theme-quartz-dark" style={{width: '100%'}}>
            <div style={{ height: 'auto'}}>
              <AgGridReact
                  enableSorting={true}
                  rowData={rows}
                  columnDefs={columnDefs}
                  domLayout='autoHeight'
                  pagination={false}
                  defaultColDef={defaultColDef}
                  autoGroupColumnDef={autoGroupColumnDef}
                  groupTotalRow={"bottom"}
                  grandTotalRow={"bottom"}
              />
            </div>
            <div style={{height: '110px'}}>
              <AgGridReact
                  enableSorting={false}
                  rowData={worthRowData}
                  columnDefs={worthColumnDefs}
                  domLayout="fixedHeight"
                  pagination={false}
                  checkboxSelection={false}

              />
            </div>
          </div>
        </Box>
      </div>
  )
      ;
};

  const formatInIndianRupees = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
    }).format(amount);
  };

  const calculateTotalWorth = (orders) => {
    return orders.reduce((total, order) => {
      if (!order['Unit Price']) return
      const unitPrice = parseFloat(order['Unit Price'].replace(/,/g, '')); // Remove commas and convert to number
      const qty = parseInt(order['Qty Due'], 10);
      return total + (unitPrice * qty);
    }, 0);
  };

  const overallOutstandingWorth = calculateTotalWorth(Object.values(data).flat());


  return (
      <div >
        <Select
            value={activeTab}
            onChange={(e) => handleTabChange(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            style={{ width: '100%' }}
        >
          <MenuItem value="OliverView">Oliver View</MenuItem>
          <MenuItem value="Default">Default View</MenuItem>
          <MenuItem value="PO">Group by PO</MenuItem>
          <MenuItem value="Component">Group by Components</MenuItem>
        </Select>
    <div className="table-container">
      {activeTab === 'Default' && renderTable()}
      {activeTab === 'PO' && renderGroupedTables(groupByPO, "PO Group")}
      {activeTab === 'Component' && renderGroupedTables(groupByComponents, "Component Group")}
    </div>

    <Footer />

  </div>
);
}