import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import DeliverCard from "../components/DeliverCard";
import DeliveryProgressCard from "../components/DeliveryProgressCard";
import MonthlyInsightsCard from "../components/MonthlyInsightsCard";
import ExpenseCard from "../components/ExpenseCard";
import IncomeExpCard from "../components/IncomeExpCard";
import IncomeProgressCard from "../components/IncomeProgressCard";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { auth, db, firebase } from "../firebase";
import { useNavigate } from "react-router-dom";


const Home = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [vendorLink, setVendorLink] = useState("");
  const [linkStatus, setLinkStatus] = useState(""); // New state to store link status
  const [user, setUser] = useState(null);

  const closeModal = () => {
    setShow(false);
  };

  const handleClose = () => setShow(false);
  const handleUpdateLink = () => {
    // Reset the vendorLink and linkStatus when the update link button is clicked
    setVendorLink("");
    setLinkStatus("");
    setShow(true);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // User is logged in
        setUser(authUser);
      } else {
        // User is logged out
        setUser(null);
      }
    });

    return () => {
      // Cleanup
      unsubscribe();
    };
  }, []);


  useEffect(() => {
    // Function to check the link status in Firebase
    const checkLinkStatus = async () => {
      try {
        const response = await fetch('https://awesome.slemachinetech.com/api/fetch-link'); // Define an API endpoint to fetch the link status
        const data = await response.json();

        console.log(data.linkStatus)

        if (data.linkStatus === "expired" || data.linkStatus === "not_found") {
          // Set the link status to determine whether to show the modal
          setLinkStatus(data.linkStatus);

          // Open the modal when the link is expired or not found
          setShow(true);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    checkLinkStatus();
  }, []);

  const handleUpload = async () => {
    try {
      console.log('vendor link',vendorLink);
      const response = await fetch('https://awesome.slemachinetech.com/api/store-link', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ link: vendorLink }),
      });

      console.log('Response:', response);

      if (response.status === 200) {
        console.log('Vendor Link was stored successfully.');
      } else if (response.status === 400) {
        console.error('The Vendor Link is not working.');
      } else {
        console.error('Error occurred while storing the Vendor Link.');
      }
    } catch (error) {
      console.error('Error:', error);
    }

    setShow(false);
  };

  return (
    <div className="body-wrapper">
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Provide the Latest Vendor link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label htmlFor="vendorLink">Vendor Link</Form.Label>
          <Form.Control
            type="text"
            id="vendorLink"
            aria-describedby="HelpBlock"
            value={vendorLink}
            onChange={(e) => setVendorLink(e.target.value)}
          />
          <Form.Text id="HelpBlock" muted>
            {linkStatus === "expired" ? (
              <span className="text-danger">The Vendor Link was expired!</span>
            ) : (
              <span>Please type the latest vendor link in the above input field.</span>
            )}
          </Form.Text>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="success" onClick={handleUpload}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid>
        <Row>
          <Col lg={12} className="text-left mb-3">
            <Button variant="primary" size="sm" className="mr-2" style={{ width: "100px" }} onClick={handleUpdateLink}>
              Update Link
            </Button>
            <span className="mx-2"></span>
            <Button variant="secondary" size="sm" style={{ width: "100px" }} onClick={() => navigate("/orders")}>
              Go to Orders
            </Button>
          </Col>
        </Row>
     


          


      </Container>
      <iframe
  width="80%"
  height="620"
  src="https://lookerstudio.google.com/embed/reporting/f4309243-6d36-40e5-a9d1-ab0bfb2b4da7/page/p_fjrvtzaged"
  frameBorder="0"
  style={{ border: "0" }} // Corrected style attribute
  allowFullScreen
  sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
></iframe>
      <Footer />
    </div>
  );
};

export default Home;