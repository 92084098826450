// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import Home from './pages/Home';
import Orders from './pages/Orders';
import Products from './pages/Products';
import Login from './pages/Login';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { user } = useAuth();

  return (
    <Router>
      
        <div className="App">
          
          <Routes>
            <Route path="/" element={user ? <Navigate to="/home" /> : <Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>} />
          </Routes>
          <ToastContainer />
        </div>
      
    </Router>
  );
}

export default App;
