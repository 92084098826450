import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from './AuthContext';
import App from './App';
import { createRoot } from 'react-dom/client';
import './standard.css';
import './print.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <AuthProvider>

        <App />

    </AuthProvider>
  </React.StrictMode>
);
