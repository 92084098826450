import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const PrivacyPolicy = () => {
    return (
        <Container>
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Effective Date:</strong> May 15, 2024
                </Typography>
                <Typography variant="body1" paragraph>
                    SLE Machine Tech ("we", "our", "us") operates the ERP system and the associated application ("Service") for internal use by the employees of SLE Machine Tech. This Privacy Policy describes how your information is collected, used, and shared when you use our Service.
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                    Information Collection and Use
                </Typography>
                <Typography variant="body1" paragraph>
                    We do not collect any personal information from users of our Service. The Service is designed to fetch and display information from our internal ERP database.
                </Typography>
                <Typography variant="body1" paragraph>
                    Users are authenticated through predefined signup keys provided by SLE Machine Tech. No additional personal data is collected or stored by the Service.
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                    Data Security
                </Typography>
                <Typography variant="body1" paragraph>
                    We take the security of our internal data seriously. The information fetched and displayed by the Service is securely handled in accordance with our internal security policies. Access to the Service is restricted to authorized employees only.
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                    Changes to This Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                </Typography>
                <Typography variant="body1" paragraph>
                    You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                    Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have any questions about this Privacy Policy, please contact us:
                </Typography>
                <Typography variant="body1" paragraph>
                    SLE Machine Tech<br />
                    Email: support@slemachinetech.com<br />
                    Address: No3, Part-1, Tass Industrial Estate, Sidco Industrial Estate, Ambattur, Chennai, Tamil Nadu 600098
                </Typography>
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;
